var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrappers" }, [
    _c("div", { staticClass: "procedure-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.$router.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.messages[_vm.params.type + "O"]))]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "procedure-detail-body" }, [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.allFlag,
                expression: "allFlag"
              }
            ],
            staticClass: "card-bot"
          },
          [
            _c(
              "el-form",
              {
                ref: "formBase",
                attrs: {
                  model: _vm.formBase,
                  rules: _vm.rulesBase,
                  "label-width": "80px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "对象", prop: "eventObj" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled:
                                    _vm.params.type === "modify" ||
                                    _vm.params.type === "view",
                                  placeholder: "请选择事件"
                                },
                                on: { change: _vm.eventChange },
                                model: {
                                  value: _vm.formBase.eventObj,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formBase, "eventObj", $$v)
                                  },
                                  expression: "formBase.eventObj"
                                }
                              },
                              _vm._l(_vm.eventObjects, function(item) {
                                return _c("el-option", {
                                  attrs: { label: item.name, value: item.name }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "触发动作", prop: "actionObj" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.formBase.eventObj ||
                                    _vm.params.type === "modify" ||
                                    _vm.params.type === "view",
                                  placeholder: "请选择触发动作"
                                },
                                on: { change: _vm.actionChange },
                                model: {
                                  value: _vm.formBase.actionObj,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formBase, "actionObj", $$v)
                                  },
                                  expression: "formBase.actionObj"
                                }
                              },
                              _vm._l(_vm.eventObjectsOne, function(item) {
                                return _c("el-option", {
                                  attrs: { label: item.name, value: item.code }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    (_vm.formBase.actionObj === "update" ||
                      _vm.formBase.actionObj === "变更") &&
                    _vm.eventTypeObjects.length
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "监听类型", prop: "typeObj" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.params.type === "modify" ||
                                        _vm.params.type === "view",
                                      placeholder: "请选择监听类型"
                                    },
                                    on: { change: _vm.typeChange },
                                    model: {
                                      value: _vm.formBase.typeObj,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formBase, "typeObj", $$v)
                                      },
                                      expression: "formBase.typeObj"
                                    }
                                  },
                                  _vm._l(_vm.eventTypeObjects, function(item) {
                                    return _c("el-option", {
                                      attrs: {
                                        label: item.eventTypeName,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.formBase.actionObj === "time_forewarn" ||
                    _vm.formBase.actionObj === "时间预警"
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "预警字段", prop: "prewarnObj" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: _vm.params.type === "view",
                                      placeholder: "请选择预警字段"
                                    },
                                    on: { change: _vm.prewarnChange },
                                    model: {
                                      value: _vm.formBase.prewarnObj,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formBase,
                                          "prewarnObj",
                                          $$v
                                        )
                                      },
                                      expression: "formBase.prewarnObj"
                                    }
                                  },
                                  _vm._l(_vm.columnsInfoOne, function(item) {
                                    return _c("el-option", {
                                      attrs: {
                                        label: item.name,
                                        value: item.code
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.formBase.actionObj === "time_forewarn" ||
                      _vm.formBase.actionObj === "时间预警") &&
                    _vm.formBase.prewarnObj
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "30%" },
                                attrs: { disabled: _vm.params.type === "view" },
                                model: {
                                  value: _vm.formBase.preType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formBase, "preType", $$v)
                                  },
                                  expression: "formBase.preType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "之前", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "之后", value: 2 }
                                })
                              ],
                              1
                            ),
                            _c("el-input-number", {
                              staticStyle: {
                                width: "50%",
                                "margin-left": "15px"
                              },
                              attrs: {
                                disabled: _vm.params.type === "view",
                                min: 1,
                                max: 30,
                                label: "几天？"
                              },
                              model: {
                                value: _vm.formBase.preTypeNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.formBase, "preTypeNum", $$v)
                                },
                                expression: "formBase.preTypeNum"
                              }
                            }),
                            _vm._v(" 天 ")
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("消息设置")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.allFlag,
                expression: "allFlag"
              }
            ],
            staticClass: "card-bot"
          },
          [
            [
              _vm._l(_vm.groupList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "groups" },
                  [
                    _c(
                      "div",
                      { staticClass: "num-tit" },
                      [
                        _c("div", { staticClass: "num" }, [
                          _vm._v("#" + _vm._s(index + 1))
                        ]),
                        _vm.params.type !== "view"
                          ? _c("el-button", {
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function($event) {
                                  return _vm.delIcon(index)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "formBase2",
                        refInFor: true,
                        attrs: {
                          model: item,
                          rules: _vm.rulesBase,
                          "label-width": "100px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "通知对象/动态",
                                      prop: "itemObjDynamic"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择通知对象/动态"
                                        },
                                        model: {
                                          value: item.itemObjDynamic,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "itemObjDynamic",
                                              $$v
                                            )
                                          },
                                          expression: "item.itemObjDynamic"
                                        }
                                      },
                                      _vm._l(_vm.columnsInfoUser, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item.name,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "用户组/动态",
                                      prop: "itemUserGroupDynamic"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择用户组/动态"
                                        },
                                        model: {
                                          value: item.itemUserGroupDynamic,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "itemUserGroupDynamic",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.itemUserGroupDynamic"
                                        }
                                      },
                                      _vm._l(_vm.columnsInfoUserGroup, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item.name,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "固定人员",
                                      prop: "itemObjStatic"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择通知对象/固定"
                                        },
                                        model: {
                                          value: item.itemObjStatic,
                                          callback: function($$v) {
                                            _vm.$set(item, "itemObjStatic", $$v)
                                          },
                                          expression: "item.itemObjStatic"
                                        }
                                      },
                                      _vm._l(_vm.usersList, function(item) {
                                        return _c("el-option", {
                                          attrs: {
                                            label:
                                              item.jobNumber + "-" + item.name,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "固定部门",
                                      prop: "itemOrg"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择固定部门"
                                        },
                                        model: {
                                          value: item.itemOrg,
                                          callback: function($$v) {
                                            _vm.$set(item, "itemOrg", $$v)
                                          },
                                          expression: "item.itemOrg"
                                        }
                                      },
                                      _vm._l(_vm.organizationList, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item.name,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "固定角色",
                                      prop: "itemRole"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择固定角色"
                                        },
                                        model: {
                                          value: item.itemRole,
                                          callback: function($$v) {
                                            _vm.$set(item, "itemRole", $$v)
                                          },
                                          expression: "item.itemRole"
                                        }
                                      },
                                      _vm._l(_vm.roleList, function(item) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item.name,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "通知渠道",
                                      prop: "itemQudao"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90%" },
                                        attrs: {
                                          disabled: _vm.params.type === "view",
                                          multiple: "",
                                          placeholder: "请选择通知渠道"
                                        },
                                        model: {
                                          value: item.itemQudao,
                                          callback: function($$v) {
                                            _vm.$set(item, "itemQudao", $$v)
                                          },
                                          expression: "item.itemQudao"
                                        }
                                      },
                                      _vm._l(_vm.qudaoMsg, function(item) {
                                        return _c("el-option", {
                                          attrs: {
                                            label: item.channelName,
                                            value: item.notifyChannel
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 23 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "主题模板",
                                      prop: "themaTemplate"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.params.type === "view",
                                        type: "textarea",
                                        maxlength: 100,
                                        "show-word-limit": "",
                                        autosize: { minRows: 1 },
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: item.themaTemplate,
                                        callback: function($$v) {
                                          _vm.$set(item, "themaTemplate", $$v)
                                        },
                                        expression: "item.themaTemplate"
                                      }
                                    }),
                                    _vm.params.type !== "view"
                                      ? _c(
                                          "div",
                                          { staticClass: "tags" },
                                          _vm._l(_vm.columnsInfo, function(
                                            tag,
                                            idx
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: tag.id,
                                                staticClass: "tag",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addTag2(
                                                      tag,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(tag.name) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 23 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "消息模板",
                                      prop: "messageTemplate"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.params.type === "view",
                                        type: "textarea",
                                        autosize: { minRows: 3 },
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: item.messageTemplate,
                                        callback: function($$v) {
                                          _vm.$set(item, "messageTemplate", $$v)
                                        },
                                        expression: "item.messageTemplate"
                                      }
                                    }),
                                    _vm.params.type !== "view"
                                      ? _c(
                                          "div",
                                          { staticClass: "tags" },
                                          _vm._l(_vm.columnsInfo, function(
                                            tag,
                                            idx
                                          ) {
                                            return _c(
                                              "el-tag",
                                              {
                                                key: tag.id,
                                                staticClass: "tag",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addTag(
                                                      tag,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(tag.name) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.params.type !== "view"
                ? _c(
                    "el-button",
                    {
                      staticClass: "add-button flex-cc",
                      on: { click: _vm.addGroup }
                    },
                    [
                      _c("div", { staticClass: "flex-sbc" }, [
                        _c("img", {
                          staticStyle: { width: "22px" },
                          attrs: {
                            src: require("@/assets/information/procedure/新增.png")
                          }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "l10",
                            staticStyle: { color: "#607FFF" }
                          },
                          [_vm._v("添加")]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ]
          ],
          2
        )
      ])
    ]),
    _vm.params.type !== "view"
      ? _c("div", { staticClass: "order-footer" }, [
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buttonLoading,
                    size: "small"
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "order-footer" }, [
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returns } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }